import React from 'react';
import AIChat from '../components/AIChat';

const AISession = () => {
  return (
    <div className="aisession">
      <h1>AI Session</h1>
      <AIChat />
    </div>
  );
};

export default AISession;