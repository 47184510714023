import React from 'react';
import './App.css';
import Navbar from './components/navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/home';
import About from './pages/about';
import SignUp from './pages/signup';
import AISession from './pages/AISession';
import Games from './pages/games';
import EmergencyButton from './components/emergencyButton';
import Contact from './pages/contact';


function App() {
  return (
    <Router>
      <Navbar />
      <EmergencyButton />
      <Routes>
        <Route path='/' exact Component={Home} />
        <Route path='/about' Component={About} />
        <Route path='/signup' Component={SignUp} />
        <Route path='/aisession' Component={AISession} />
        <Route path='/games' Component={Games} />
        <Route path='/contact' Component={Contact} />
      </Routes>
    </Router>
  );
}

export default App;
