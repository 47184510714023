import React, { useState } from 'react';
import './emergencyButton.css';

const EmergencyButton = () => {
  const [showOptions, setShowOptions] = useState(false);

  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };

  return (
    <div className="emergency-button-container">
      <button className="emergency-button" onClick={toggleOptions}>
        EMERGENCY
      </button>
      {showOptions && (
        <div className="emergency-options">
          <button onClick={() => alert('Calling emergencies...')}>
            Call Emergencies
          </button>
          <button onClick={() => alert('I need to talk to someone...')}>
            I Need to Talk to Someone
          </button>
        </div>
      )}
    </div>
  );
};

export default EmergencyButton;