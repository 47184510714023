import React from 'react';
import './navbar.css';

const Navbar = () => {
  return (
    <>
      <iframe
src="https://www.chatbase.co/chatbot-iframe/1G-nTFuqPl7kvcKjq1YNE"
width="100%"
height="700"
frameborder="0"
></iframe>
    </>
  );
}

export default Navbar;