import React from 'react';

const Games = () => {
  return (
    <div>
      <h1>Games</h1>
    </div>
  );
};

export default Games;