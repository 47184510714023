import React from 'react';
import '../style/about.css';

const About = () => {
  return (
    <>
    <h1>About</h1>

    <p>
      Trauma Buddies is on a mission to help people that need it.
    </p>
    </>
  );
};
  
export default About;